function mobileContent() {
	var toDuplicate = document.querySelectorAll('[data-duplicator]');
	for (var i = 0; i < toDuplicate.length; i++) {
		var el = toDuplicate[i];
		var target = document.querySelector('[data-duplicate="' + el.dataset.duplicator + '"]');

		if(target) {
			var clone = el.cloneNode(true);
			clone.classList.remove('visible-md');
			clone.classList.remove('visible-lg');
			clone.classList.add('hidden-md');
			clone.classList.add('hidden-lg');
			target.appendChild(clone);
		}
		
	}
}



$(document).ready(function() {

	mobileContent();


	$(window).on('scroll', function() {
        var y_scroll_pos = window.pageYOffset;
        var scroll_pos_test = 50;

        if(y_scroll_pos > scroll_pos_test) {
            $('header').addClass('scrolled');
        }
        if(y_scroll_pos < scroll_pos_test) {
            $('header').removeClass('scrolled');
        }
    });

  //   $('toggleSearch').on('click', function(){
  //   	document.querySelector('.hello').scrollIntoView({
		//   behavior: 'smooth'
		// });
  //   });

  	if (is.desktop()) {
  		jQuery('.shareLink').live('click', function(){
			newwindow=window.open($(this).attr('href'),'','height=328,width=555');
			if (window.focus) {newwindow.focus()}
			return false;
		});
  	}

	$(".topAlert_close").on("click", function () {
	    $(this).parents('.topAlert').slideUp();
	});

	if ($(window).width() < 993) {

		$('.toggleNav').on('click', function(){
			$('.subnav').slideUp();
			$('.subnav').parents('li').removeClass('opened');
			$(this).toggleClass('active');
			$('.toggleSearch').removeClass('active');
			$('.header_mainNav').slideToggle();
			$('body').toggleClass('noscroll');
			$('.toggleSearch').removeClass('active');
			$('.header_search__wrapper').slideUp().removeClass('active');
			$('.header_topNav').toggleClass('active');
		});

		$('.firstLevel > li:has(".subnav")').append('<button class="toggleSubnav Btn Icon-center" data-icon="keyboard_arrow_down" ></button>');

	   	$('.toggleSubnav').on('click', function(){
	   		event.preventDefault();
	   		if ($(this).siblings('.subnav').hasClass('active')) {
	   			$(this).siblings('.subnav').slideUp().removeClass('active');
	   			$(this).parents('li').removeClass('opened');
	   		}
	   		else {
	   			$('.subnav').slideUp();
				$(this).siblings('.subnav').slideToggle().addClass('active');
				$(this).parents('li').addClass('opened');
	   		}
	   	});
	}


	$('.toggleSearch').on('click', function(){
		if ($(window).width() < 993) {
			$('.subnav').slideUp();
			$('.toggleNav').removeClass('active');
			$(this).toggleClass('active;')
			$('.header_topNav').removeClass('active');
			$('.header_mainNav').slideUp();
			$('body').removeClass('noscroll');
		}
		$(this).toggleClass('active');
		$('.header_search__wrapper').slideToggle().toggleClass('active');
	});


	$('.iframe_container').on('click', function(){
		$('.iframe_container iframe').css("pointer-events", "auto");
	});

	$('.iframe_container').on('mouseleave', function(){
		$('.iframe_container iframe').css("pointer-events", "none");
	});










	
   	//	-------------------------------------------------
	// FORM Utilities

	// when upload a file, get it's name and display it in the label
	$('input[type="file"]').change(function (e) {
	    var fileName = e.target.files[0].name;

	    $(this).parent().find('label').text(fileName);
	});

	// when submiting a form, disable the button and show "sending..." in in
	$('form').submit(function (e) {
	    var submit_button = $(this).find('button[type="submit"]');
	    var span_loading_msg = submit_button.parent().find('span');

	    submit_button.addClass('disabled').text(span_loading_msg.text());
	});

	//  -------------------------------------------------
	//  TILES Utility

		$(".match-height").each(function(){
			// Get highest parent from element (of type .row)
			var highestParent = $(this).parents(".row").last(); 
			// Get height of reference parent
			var refHeight = highestParent.height();
			// Give determined height to element
			$(this).height(refHeight);
		});


	//  -------------------------------------------------
	//  ACCORDIONs - Slide down - up

		var accordion = $(".Accordions");
		var accordion_length = accordion.length;

		if (accordion_length > 0) {

			var block = $(".Accordions_block");
			var header = $(".Accordions_header");
			var title = $(".Accordions_title");
			var content = $(".Accordions_content");
			
			header.click(function(){

				var self = $(this);

				if (!accordion.hasClass('Accordions-collapse')) {
					var accordions_active = $('.Accordions_block .Accordions_header.active');
					
					accordions_active.removeClass('active');
					
					if (accordions_active.length > 0) {
						accordions_active.next().slideUp(function(){
							if (self[0] != $(this).prev()[0]) {
								slideAccordion('toggle', self);
							}
						});						
					}
					else {
						slideAccordion('toggle', self);
					}
				}
				else {
					slideAccordion('toggle', self);									
				}
				return false;
			});

		};

		function slideAccordion(typeSlide,accordion) {
			switch(typeSlide.toLowerCase()) {
			    case 'up':
			    	
			        accordion.removeClass("active");
					accordion.next().slideUp(200);
			        break;
			    case 'down':
			    	
			        accordion.addClass("active");
					accordion.next().slideDown(200);
			        break;
			    case 'toggle':
			    	
			 		accordion.toggleClass("active");
					accordion.next().slideToggle(200);
			        break;
			}
		}

	//  -------------------------------------------------
	//  TOGGLE Utility

	$("[data-toggle]").click(function(){
		var target = $(this).data("toggle");
		$("[data-elem='"+target+"']").slideToggle();
	});

	$("menu nav .mobile-actions [data-toggle='menu']").click(function(){
		$(this).parents("menu").toggleClass("active");
	});


	//  -------------------------------------------------
	//  TABS Utility


	$('.nav-tabs a').click(function (e) {
	  e.preventDefault();
	  $(this).tab('show');
	});


	$(window).on('load', function(){
	/* ////////////////////////////////////////
	//
	// Init
	//
	/////////////////////////////////////// */

	//du code soit pour le loading mask ou pour initialiser le loading du site

		}).scroll(function(){
			scrollContent();
		});

		/* ////////////////////////////////////////
			//
			// Scroll content
			//
			/////////////////////////////////////// */
		function scrollContent(){
			var totalScroll = $(document).height() - $(window).height();

			//if(browserMobile){
			newScroll = $(window).scrollTop();
			// } else {
			// 	if(whichBrs() == 'Safari' || whichBrs() == 'Chrome'){
			// 		newScroll = $('body').scrollTop();
			// 	} else {
			// 		newScroll = $('html,body').scrollTop();
			// 	}
			// }

			currentScroll = newScroll;
			/* To-load
			================================================== */

			/* new system */
			$('.ToAnim').each(function(){
				var object = $(this);					
				if(newScroll + $(window).height() * 1 > $(this).offset().top){
					object.addClass('IsAnim');
					object.removeClass('DoneAnim');
				}
				else if(newScroll + $(window).height() < $(this).offset().top) {
					object.removeClass('IsAnim');
					object.addClass('DoneAnim');
				}
			});

			/* old system */
			// $('.to-load').each(function(){
			// 	var object = $(this);		
					
			// 	if(newScroll + $(window).height() * 0.85 > $(this).offset().top){
			// 		object.removeClass('no-anim');
			// 		object.addClass('loaded');
			// 	} else if(newScroll + $(window).height() < $(this).offset().top) {
			// 		object.addClass('no-anim');
			// 		object.removeClass('loaded');
			// 	}
					
			// });
			
		}

		setTimeout(function(){
			scrollContent();
		}, 1000);

		

			// $('#background > div > img').each(function(){
			// 	var posLeft = ($(this).width() - $(this).parent().width()) / 2;
			// 	$(this).css({'left': - posLeft});
			// });

			scrollContent();
});