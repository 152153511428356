function selectSalleParking(salle_id,lan)
{
    $('#label-show-parking').show();
    $.post('/json?module=spectacle&method=getShowsParking',{'salle_id':salle_id, 'lan':lan},function(r){
        if(!r.error)
        {
            $('#select-show-parking').removeAttr('disabled').html(r.message);
        }
    });
}

function selectSalleTransport(salle_id,lan)
{
    $('#label-show-transport').show();
    $.post('/json?module=spectacle&method=getShowsTransport',{'salle_id':salle_id, 'lan':lan},function(r){
        if(!r.error)
        {
            $('#select-show-transport').removeAttr('disabled').html(r.message);
        }
    });
}

function selectSalleSpectacle(salle_id,lan,form)
{

    var label = form.querySelector('.label-show');
    $(label).show();


    $.post('/json?module=spectacle&method=getShows',{'salle_id':salle_id, 'lan':lan},function(r){

        if(!r.error)
        {
            var select = form.querySelector('.select-show');

            $(select).removeAttr('disabled').html(r.message);
        }
    });
}

if ($.browser.msie) {
    $('html').addClass('ie').addClass('ie'+parseInt($.browser.version,10));
}

var emploisuccess = function() {
    $('.emploiForm').fadeOut(500,function() {$('.emploisuccess').removeClass('hide')});
}

var emploierror = function() {
    $('.emploiForm .Btn').fadeIn();
    $('.emploiForm').append('<p class="text-error">Une erreur est survenue, veuillez r&eacute;essayer plus tard.</p>');
}

var retina = window.devicePixelRatio > 1;
$(function() {


    $('.billetterie-salle').each(function(){
        $(this).change(function(e){

            var form = this.form;

            if (this.value){

                selectSalleSpectacle(this.value,form.querySelector('.inputLan').value,form);
            }
            else
            {
                form.querySelector('.label-show').style.display='none';

                var s=form.querySelector('.select-show');
                s.value='';
                s.setAttribute('disabled','');
            }

        });


    });

    $('.js-show-filter button').click(function()
    {
        $('.js-no-result').hide();
        var id = $(this).data('salle');
        $('.js-show-filter button').addClass('Btn-bordered');
        $(this).removeClass('Btn-bordered');
        $('.js-show-ul li').each(function()
        {
            var salle = $(this).data('salle')
            if( salle == id || id == '0')
            {
                $(this).fadeIn();
            }
            else
            {
                $(this).fadeOut();
            }
        });
        if(!$('.js-show-ul li:visible').length)
        {
            $('.js-no-result').show();
        }
    });

    if( $('.clickMe').length )
    {
        $('html, body').animate({
            scrollTop:$('.clickMe').offset().top
        }, 1000);
        //$('.clickMe').click();
        return false;
    }

    $(".scrollto").click(function() { // Mettre class .scrollto sur le bouton et mettre attribut data-target="#id" et data-duration="1000"
        var scrolltarget = $(this).data('target');
        $('html, body').animate({
            scrollTop:$(scrolltarget).offset().top
        }, $(this).data('duration'));
        return false;
    });

    //svgeezy.init();

    $('body').on('touchstart.dropdown', '.dropdown-menu', function (e) { e.stopPropagation(); }); // iPad fix for unclickable nav/subnav

    $('.carousel').carousel({
      interval: 5000
    })
    $("button.close").on('click',function(){
        $(this).parent().slideUp();
    });

    if ($.browser.msie && $.browser.version<9) {
        $('header nav ul > li:last-child').addClass('last').prev().addClass('last');
    }
/*
    if ($('.instagram-gal a').length>0) {
        $('.instagram-gal a').magnificPopup({ 
          type: 'image',
          gallery:{enabled:true}
        });
    }*/
    if ($('.img-gal a').length>0) {
        $('.img-gal a').magnificPopup({ 
          type: 'image',
          gallery:{enabled:true}
        });
    }
    if ($('.fancybox').length>0) {
        $('.fancybox').magnificPopup({ 
          type: 'image',
          gallery:{enabled:true}
        });
    }
    if ($('.fancyboxvideo').length>0) {
        $('.fancyboxvideo').magnificPopup({ 
          type: 'iframe',
          gallery:{enabled:true}
        });
    }

    $('#datesdrop').on('change',function(){
        window.location.assign($('#datesdrop option:selected').data('url'));
    });
    $('#newsletter').submit(function() {
        $.post('/json?module=infolettre&method=add',$(this).serializeArray(),function(data) {
            if(data.error == undefined) {
                $('#newsletter').hide().next().show();
            } else {
                $('#newsletter').append(data.message);
            }
            
        });
        return false;
    });
    $('.contactForm').submit(function() {
        $.post('/json?module=contactform&method=submit',$(this).serializeArray(),function() {
            $('.contactForm').hide().next().show();
        });
        return false;
    });
    $('#beneform').submit(function() {
        $.post('/json?module=benevole&method=benevole',$(this).serializeArray(),function() {
            $('#beneform').hide().next().show();
        });
        return false;
    });

    $('.emploiForm .Btn').click(function() {
        $('.emploiForm').attr('action', '/json?module=emploiform&method=submit');
    });

    $('.emploiForm').submit(function() {
        $(this).find('.Btn').fadeOut();
    });

    /* Mobile Nav Events START */
    var el = $('.mobile-nav > ul'),
        curHeight = el.height(),
        autoHeight = 0,
        subHeight = 0;
        menuHeight = 40;

    $('.bt-menu').click(function(){
            autoHeight = el.css('height', 'auto').height();
        if(!el.hasClass('open')){
            el.height(curHeight).animate({height: autoHeight}, 400);
        }else{
            el.height(autoHeight).animate({height: curHeight}, 400);
        }
        //console.log('click!');
        el.toggleClass('open');
        return false;
    });

    $('.mobile-nav > ul > li > a').click(function(){
        if($(this).parent().children().length > 1){
            $(this).next().addClass('active');
            subHeight = $(this).next().find('ul').height()+menuHeight;
            if(subHeight>autoHeight){
                el.height(autoHeight).animate({height: subHeight}, 200);
            }
            return false;
        }
    });

    $('.mobile-nav > ul > li > div b').click(function(){
        $('.mobile-nav div').removeClass('active');
        if(subHeight>autoHeight){
            el.height(subHeight).animate({height: autoHeight}, 200);
        }
        return false;
    });
    /* Mobile Nav Events END */

    //Code pour le calendrier
    $(document).ready(function()
    {
        $('body').on('click', '.calendar-next, .calendar-prev', function(e)
        {
            e.preventDefault();
            var year = $(this).data('year');
            var month = $(this).data('month');
            $('#calendar-container').load('?month='+month+'&year='+year+' #calendar-container');
        });
    });

//  $('nav.subnav.box.subvideos a').click(function(){
//     $('html, body').animate({
//         scrollTop: $( $.attr(this, 'href') ).offset().top
//     }, 500);
//     return false;
// });

    $('#video iframe').load(VideoLoaded);

    var vid = $("#video video")[0];
    if(vid)
    {
        vid.onloadeddata = VideoLoaded();
    }   

    // $(document).on("loadeddata","#video video",VideoLoaded);

    $('.subvideos a').click(function() { 
        //alert($(this).data("videoid"));
        videoLinkClicked = $(this);

        if(!videoLinkClicked.parent().hasClass("active"))
        {
            $('.subvideos li.active').removeClass("active");
            videoLinkClicked.parent().addClass("active");

            //$(".ajax-loader").css("display","block");
            $(".ajax-loader").fadeIn(300);
            
            $("#desc-video").animate({opacity: 0},600);
            
            $("#title-video").animate({opacity: 0},600);


            $('.videoActive').fadeOut(/*animate({ opacity: 0 },*/600,function(){
                $.post('/json?module=video&method=changeVideo',{videoid:videoLinkClicked.data("videoid")},function(data) 
                {
                    if(data.error == undefined) 
                    {
                        if(data.message.videoType == 'youtube')
                        {
                            if($(".videoActive").prop("tagName") != "iframe")
                            {
                                $(".videoActive").css("display","none");
                                $(".videoActive").removeClass("videoActive");
                                $('#video iframe').addClass("videoActive");                             
                            }                           

                            $('#video iframe').prop("src","http://www.youtube.com/embed/"+data.message.src);
                            $('#video source').prop("src","");
                        }
                        else
                        {
                            if($(".videoActive").prop("tagName") != "video")                            
                            {
                                $(".videoActive").css("display","none");
                                $(".videoActive").removeClass("videoActive");
                                $('#video video').addClass("videoActive");                              
                            }                           

                            $('#video source').prop("src",data.message.src);
                            $('#video iframe').prop("src","");
                        }


                        $("#desc-video").html(data.message.description);
                        $("#title-video").text(data.message.title);                     
                    }               
                    // else 
                    // {
                    //  $('#video').append(data.message);
                    // }
                });
            });

            // $('#video '+videoSelector).remove();
        }
        
        
        return false;
    });


});


    function VideoLoaded() 
    {
        $(".ajax-loader").fadeOut(300);
        $('.videoActive').fadeIn(600);//animate({ opacity: 1 },600);
        $("#desc-video").animate({opacity: 1},600);
        $("#title-video").animate({opacity: 1},600);
    }
